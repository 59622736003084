import React, { useEffect, useState } from 'react';

import useStyles from 'hooks/useStyles';

import CustomFiltersPanel from 'components/pages/users/Filters/CustomFiltersPanel';

import { makeFilters } from 'components/utils/filters';

import filterStyle from 'styles/users/filters.css';
import style from 'styles/analyze/widgetHeader.css';

const styles = style.locals || {};
const filterStyles = filterStyle.locals || {};

function WidgetFiltersTags({
  widgetHeaderConfig = {},
  updateWidgetFilters,
  filtersData,
  disabled,
  isShowFiltersAsCollapsed,
}) {
  const [isShowFilterTags, setIsShowFilterTags] = useState(false);
  const [widgetFilters, setWidgetFilters] = useState([]);

  useStyles([filterStyle, style]);

  useEffect(() => {
    const updateFilters = makeFilters(widgetHeaderConfig.filters, filtersData);
    setWidgetFilters(updateFilters);
  }, [widgetHeaderConfig.filters]);

  function onUpdateFilters({ newFilters }) {
    const filtersWithoutGlobalFilters = newFilters.filter((filter) => !filter.isDisabled);
    const filtersForRequest = filtersWithoutGlobalFilters.map((filter) => ({ data: filter.data, kind: filter.config.kind, isUiOnly: filter.isUiOnly }));
    updateWidgetFilters(filtersForRequest);
  }

  if (isShowFiltersAsCollapsed) {
    return (
      <div className={styles.reportsFilterTagsWrapper}>
        <CustomFiltersPanel
          filters={widgetFilters}
          setFilters={(newFilters) => onUpdateFilters({ newFilters })}
          disabled={disabled}
        />
      </div>
    );
  }

  return (
    <div className={styles.reportsFilterTagsWrapper}>
      <div className={filterStyles.filterTagsCollapse} onClick={() => setIsShowFilterTags((prev) => !prev)}>
        {isShowFilterTags ? 'Hide ' : 'Show '}
        {`filters (${widgetFilters.length})`}
        <span className={isShowFilterTags ? filterStyles.arrowUpBlue : filterStyles.arrowDownBlue} />
      </div>

      {isShowFilterTags
        && (
          <CustomFiltersPanel
            filters={widgetFilters}
            setFilters={(newFilters) => onUpdateFilters({ newFilters })}
            disabled={disabled}
          />
        )}
    </div>
  );
}

export default WidgetFiltersTags;
